import React, { useState } from 'react';
import i18n from '../../../components/i18n';
import { useForm } from "react-hook-form";
import { Input } from '../../../components/Input';
import { Button, Row, Col } from 'react-bootstrap';
import { useTransactions } from '../../../hooks/useTransactions';
import { GetBalance, StartListeningSocket, Formating } from '../../../hooks/helper';
import { useAuth } from '../../../hooks/useAuth';
import { useHistory } from 'react-router';
import * as Config from "../../../config";

export const Withdraw = () => {
    const { register, handleSubmit, errors, getValues, reset } = useForm();
    const [loading, setLoading] = useState(false);
    const [thankYou, setThankYou] = useState(false);
    const [error, setError] = useState(false);
    const useTransaction = useTransactions();
    const balance = GetBalance();
    const auth = useAuth();
    const history = useHistory();
    StartListeningSocket(auth.info.user.token);

    const onSubmit = () => {
        const data = getValues();
        if(balance.free_margin >= data.amount) {
            const methods = [];
            methods['withdraw'] = {title: 'Withdraw', id: 6};
            data.method = 'withdraw';
            data.amount = -Math.abs(data.amount);
            setLoading(true);
            useTransaction.create(data, reset, setLoading, setThankYou, ()=> {history.push('/platform/BTCUSD')}, methods);
            console.log(thankYou);
        } else {
            setError(true);
        }
    }

    return (
        <div className="withdraw">
            {!thankYou ? <>
                <Row>
                    <Col xs={12} md={6}>
                    {balance.free_margin > 0 ?
                        <div className="form pr-0 pr-md-4">
                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                <h2>{i18n.t('transactions.withdraw.title')}</h2>
                                <strong>Available Funds: {Formating(balance.free_margin, Config.CURRENCY_SYMBOL)}</strong>
                                <Input name="amount" type="number" reference={register({ required: true })} errors={errors} title={i18n.t('transactions.withdraw.amount.label')} placeholder={i18n.t('transactions.withdraw.amount.placeholder')} />
                                <label className="field">
                                    {i18n.t('transactions.withdraw.comments.label')}
                                    <textarea name="comments" ref={register({ required: true })}></textarea>
                                </label>

                                <Button block className={`button ${loading ? 'loading' : ''}`} disabled={loading} type="submit">{i18n.t('transactions.withdraw.button')}</Button>
                                {error && <>Maximum withdraw can be {Formating(balance.free_margin, Config.CURRENCY_SYMBOL)}</>}
                            </form>
                        </div>
                        : <div className="form pr-0 pr-md-4">
                            You don't have balance to withdraw
                        </div>}
                    </Col>
                    <Col xs={12} md={6}>
                        <div className="info pt-5 pt-md-0">
                            <h2>Withdraw Agreement</h2>
                            <p>This form serves as an official request to withdraw and transfer funds from the brokers trading account into the account specified below.</p>
                            <p>I understand that the broker does not permit any third party transfers. The beneficiary contact listed in my transfer details below, is the same as the owner of my trading account. I understand that I might be subject to the appropriate bank fees and transfer charges applicable to this request. I authorize the broker to deduct the necessary fees from my account or reduce my transfer amount accordingly.</p>
                            <p>I understand that the broker may request additional information, identification or verification from me in order to authorize my request. I further understand that although withdrawals are dealt with promptly, it can take approximately seven business days before the funds appear on my account statement, provided that all documentation required from me by the broker have been reviewed and approved to their satisfaction.</p>
                        </div>
                    </Col>
                </Row>
            </> : <>
                    <h3 dangerouslySetInnerHTML={{__html: i18n.t('transactions.withdraw.successful_request')}}></h3>
                </>}
        </div>
    );
}