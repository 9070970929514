import React, { useContext } from "react";
import { AppContext } from "../../App";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import './Footer.scss';

const Footer = () => {
    const context = useContext(AppContext);
    const auth = useAuth();
    return <>{context && <>
        <div className="footer-top">
            <div className="thm-container">
                <div className="py-5 text-center">
                    <img src="assets/img/payment-icons.png" className="payment-icons" alt="" />
                </div>
            </div>
        </div>


        <footer className="site-footer pt-5">
            <div className="thm-container">
                <div className="d-flex masonary-layout">

                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="single-footer-widget">
                            <div className="title">
                                <h3>About</h3>
                            </div>
                            <ul className="links-list">
                                {auth.isLoggedIn() ? <li><Link to="/platform">Platform</Link></li> : <li><Link to="/">Home</Link></li>}
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                                <li><Link to="/referrals">Referrals</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="single-footer-widget">
                            <div className="title">
                                <h3>Legal</h3>
                            </div>
                            <ul className="links-list">
                                <li><Link to="/terms-conditions">Terms&Conditions</Link></li>
                                <li><Link to="/cookies-policy">Cookies Policy</Link></li>
                                <li><Link to="/aml-policy">AML Policy</Link></li>
                                <li><Link to="/kyc-policy">KYC Policy</Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

            <div className="footer-bottom thm-container clearfix mt-5 pt-3 pb-5">
                <div className="pull-left copy-text" dangerouslySetInnerHTML={{ __html: context.copyright }}></div>
            </div>
        </footer>
    </>}
    </>
};

export default Footer;