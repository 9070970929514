import React, {useState} from 'react'
import './Popup.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Popup = ({ popups, auth}) => {
    const [InfoModalIsVisible, setInfoModalIsVisible] = useState(true);
    
    const closeModal = (id) => {
        setInfoModalIsVisible(false);
        auth.readedPopup(id);
    }

    return (
        <>
        {popups && <>
            {popups.map((item, i)=>{
                return <div key={i}>
                    {InfoModalIsVisible && <>
                        <div className="positionInfo-bg" onClick={() => closeModal(item.id)}></div>
                        <div className="positionInfo-modal">
                            <div className="title">
                                {item.title}
                                <span onClick={() => closeModal(item.id)}><FontAwesomeIcon icon={faTimes} /></span>
                            </div>
                            <div className="content" dangerouslySetInnerHTML={{ __html: item.content }}></div>
                        </div>
                    </>}
                </div>
            })}
        </>}
        </>
    )
}

export default Popup