import React, { useState } from 'react';
import './Positions.scss';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp, faSortDown, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { Formating, CalculateRealTimeProfit, checkDataMarket } from '../../../hooks/helper';
import { useAuth } from '../../../hooks/useAuth';
import { SLTP } from '../../../components/Modals/SLTP/SLTP';
import { PositionInfo } from '../../../components/Modals/PositionInfo/PositionInfo';
import i18n from '../../../components/i18n';
import { usePositions } from '../../../hooks/usePositions';
import * as Config from "../../../config";

const Positions = ({dataMarket, positions}) => {
    const auth = useAuth();
    const usePosition = usePositions();
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [infoModalIsVisible, setInfoModalIsVisible] = useState(false);
    const [currentPosition, setCurrentPosition] = useState(null);

    const closePosition = (id, symbol, direction) => {
        if (checkDataMarket(symbol, dataMarket)) {
            let value = dataMarket[symbol].sell.toString();
            if (direction === 'sell') {
                value = dataMarket[symbol].buy;
            }
            usePosition.ClosePosition(auth.info, id, value);
        }
    }

    const openSLTP = (position) => {
        setModalIsVisible(true);
        setCurrentPosition(position);
    }

    const openPositionInfo = (position) => {
        setInfoModalIsVisible(true);
        setCurrentPosition(position);
    }

    return (
        <div className="bottom">
            <div className="card">
                <div className="wrapperContentBottom">
                    <div className="title">{i18n.t('platform.positions')}</div>
                    <div className="positions">
                        <Row className="header-table">
                            <Col xs={4} sm={3} md={2}>{i18n.t('platform.market')}</Col>
                            <Col xs={4} sm={3} md={2}>{i18n.t('platform.amount')}</Col>
                            <Col xs={2} sm={2} md={2} className="d-none d-md-block">{i18n.t('platform.leverage')}</Col>
                            <Col xs={3} sm={2} md={2} className="d-none d-md-block pr-0">Protected</Col>
                            <Col xs={2} sm={2} md={2} className="d-none d-md-block">{i18n.t('platform.entry')}</Col>
                            <Col xs={4} sm={3} md={2}>{i18n.t('platform.pl')}</Col>
                            <Col xs={4} sm={3} md={2} className="d-none d-sm-block">{i18n.t('platform.options')}</Col>
                        </Row>
                        <div className="positionsDataRow">
                            {(positions.open.length > 0) ? positions.open.map((position, index) => {
                                const profit = CalculateRealTimeProfit(position, dataMarket);
                                if (position.amount > 0) {
                                    return (
                                        <Row key={index} className="text-left">
                                            <Col xs={4} sm={3} md={2}
                                                onClick={() => openPositionInfo(position)}
                                            >{(position.direction === 'buy') ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />}
                                                {position.protected === 1 && <img src="assets/img/protected.png" className="protected-icon" alt="Protected!" /> }
                                                {position.symbolName}
                                            </Col>
                                            <Col xs={4} sm={3} md={2}>{Formating(position.amount)}</Col>
                                            <Col xs={2} sm={2} md={2} className="d-none d-md-block">1:{position.leverage}</Col>
                                            <Col xs={2} sm={2} md={2} className="d-none d-md-block">{position.protected === 1 ? 'Yes' : 'No'}</Col>
                                            <Col xs={2} sm={2} md={2} className="d-none d-md-block">{Formating(position.open_rate)}</Col>
                                            {checkDataMarket(position.symbol, dataMarket) ? 
                                                <Col xs={4} sm={3} md={2} className={`profit ${(profit < 0) ? 'minus' : 'plus'}`}>{Formating(profit, Config.CURRENCY_SYMBOL)}</Col>
                                            :
                                                <Col xs={4} sm={3} md={2}>Will be back soon</Col>
                                            }
                                            <Col xs={4} sm={3} md={2} className="d-none d-sm-block">
                                                {checkDataMarket(position.symbol, dataMarket) ?
                                                <>
                                                    <button className="sltp" onClick={() => openSLTP(position)}>{i18n.t('platform.sltp')}</button>
                                                    <button className="closePosition" onClick={() => closePosition(position.id, position.symbol, position.direction)}>{i18n.t('platform.close')}</button>
                                                </>
                                                :
                                                <>Will be back soon</>
                                            }
                                            </Col>
                                        </Row>
                                    );
                                }
                            }) :
                                <div className="mt-2 text-center">{i18n.t('platform.no_positions_to_show')}.</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="wrapperContentBottom">
                    <div className="title">{i18n.t('platform.history')}</div>
                    <div className="positions">
                        <Row className="header-table">
                            <Col xs={4} sm={3} md={2}>{i18n.t('platform.market')}</Col>
                            <Col xs={4} sm={3} md={2}>{i18n.t('platform.amount')}</Col>
                            <Col xs={2} sm={2} md={2} className="d-none d-md-block">{i18n.t('platform.leverage')}</Col>
                            <Col xs={3} sm={2} md={2} className="d-none d-md-block pr-0">Protected</Col>
                            <Col xs={2} sm={2} md={2} className="d-none d-md-block">{i18n.t('platform.entry')}</Col>
                            <Col xs={3} sm={3} md={2} className="d-none d-sm-block">{i18n.t('platform.close')}</Col>
                            <Col xs={4} sm={3} md={2}>{i18n.t('platform.pl')}</Col>
                        </Row>
                        <div className="positionsDataRow">
                            {(positions.history.length > 0) ? positions.history.map((position, index) => {
                                if (position.amount > 0) {
                                    return (
                                        <Row key={index} className="text-left">
                                            <Col xs={4} sm={3} md={2}
                                                onClick={() => openPositionInfo(position)}
                                            >{(position.direction === 'buy') ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />}
                                                {position.protected === 1 && <img src="assets/img/protected.png" className="protected-icon" alt="Protected!" /> }
                                                {position.symbolName}
                                            </Col>
                                            <Col xs={4} sm={3} md={2}>{Formating(position.amount)}</Col>
                                            <Col xs={2} sm={2} md={2} className="d-none d-md-block">1:{position.leverage}</Col>
                                            <Col xs={2} sm={2} md={2} className="d-none d-md-block">{position.protected === 1 ? 'Yes' : 'No'}</Col>
                                            <Col xs={2} sm={2} md={2} className="d-none d-md-block">{Formating(position.open_rate)}</Col>
                                            <Col xs={3} sm={3} md={2} className="d-none d-sm-block">{Formating(position.closed_rate)}</Col>
                                            <Col xs={4} sm={3} md={2} className={`profit ${(position.profit < 0) ? 'minus' : 'plus'}`}>{Formating(position.profit, Config.CURRENCY_SYMBOL)}</Col>
                                        </Row>
                                    );
                                }
                            }) :
                                <div className="mt-2 text-center">{i18n.t('platform.no_positions_to_show')}.</div>
                            }
                        </div>
                    </div>

                </div>
            </div>
            {currentPosition !== null &&
                <SLTP position={currentPosition} setCurrentPosition={setCurrentPosition} modalIsVisible={modalIsVisible} setModalIsVisible={setModalIsVisible} dataMarket={dataMarket} />
            }
            {currentPosition !== null &&
                <PositionInfo position={currentPosition} setCurrentPosition={setCurrentPosition} infoModalIsVisible={infoModalIsVisible} setInfoModalIsVisible={setInfoModalIsVisible} />
            }
        </div>
    );
}
export default Positions;