import React from "react";
import convert from "htmr";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import styled from "styled-components";
import Image from "../Image";

const ContainerStyled = styled(Container)`
  strong {
    font-weight: bold;
    color: rgb(50, 50, 50);
  }
`;


export default (props) => {
  return (
    <ContainerStyled className={[`component--${props.name}`, props.class]} id={props.id} dangerouslySetInnerHTML={{ __html: props.html }} />
  )
};
