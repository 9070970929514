import React from 'react';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import { getLanguageForTradingview } from '../../../hooks/helper';
import './Chart.scss';

const Chart = ({ symbol }) => {
    const themeMode = localStorage.getItem('theme-mode') ? localStorage.getItem('theme-mode') : 'dark';
    return (
        <TradingViewWidget
            symbol={symbol.code}
            theme={Themes[themeMode.toUpperCase()]}
            // style
            timezone="Etc/UTC"
            locale={getLanguageForTradingview}
            autosize={true}
            range="1d"
            hide_top_toolbar={false}
            hide_side_toolbar={false}
            withdateranges={true}
            details={true}
            hideideas={true}
            allow_symbol_change={false}
            toolbar_bg="#ffffff"
        />
    );
}
export default Chart;
