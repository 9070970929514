import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LngDetector from 'i18next-browser-languagedetector';

const en = require('../i18n/en.json');
const de = require('../i18n/de.json');
const it = require('../i18n/it.json');
const es = require('../i18n/es.json');

i18n.use(initReactI18next)
    .use(LngDetector)
    .init({
        resources: {
            en: { translation: en },
            de: { translation: de },
            it: { translation: it },
            es: { translation: es }
        },
        // lng: 'en',
        keySeparator: '.',
        fallbackLng: 'en',
        whitelist: ['de', 'en', 'it', 'es'],
        interpolation: {
            escapeValue: false
        }
    });
export default i18n;