import axios from "axios";
//
import * as Config from "../config";
const localStoragePagesKey = 'pages';

export async function fetchCollection(collection) {
  return axios
    .get(
      `${Config.COCKPIT_HOST}/api/collections/get/${collection}?token=${
      Config.COCKPIT_API_TOKEN
      }`,
      {
        filter: {
          published: true
        },
        populate: 1,
        simple: 1,
        lang: localStorage.getItem('i18nextLng')
      }
    )
}

export async function fetchCollectionBySlug(collection, slug) {
  return axios
    .post(
      `${Config.COCKPIT_HOST}/api/collections/get/${collection}?token=${
      Config.COCKPIT_API_TOKEN
      }`,
      {
        filter: {
          published: true,
          title_slug: slug
        },
        populate: 1,
        simple: 1,
        lang: localStorage.getItem('i18nextLng')
      }
    )
}

export async function fetchSingleton(singleton) {
  return axios
    .get(
      `${Config.COCKPIT_HOST}/api/singletons/get/${singleton}?token=${
      Config.COCKPIT_API_TOKEN
      }`
    )
    .then(singletons => singletons.data);
}

export function fetchImageStyle(style, path) {
  if (!path.startsWith(Config.COCKPIT_UPLOADS_DIR)) {
    path = `${Config.COCKPIT_UPLOADS_DIR}${path}`;
  }

  return (
    (style &&
      axios.get(
        `${Config.COCKPIT_HOST}/api/imagestyles/style/${style}?token=${
        Config.COCKPIT_API_TOKEN
        }&src=${path}`
      )) ||
    `${Config.COCKPIT_HOST}/${path}`
  );
}

export async function submitForm(formName, values) {
  return axios
    .post(
      `${Config.COCKPIT_HOST}/api/forms/submit/${formName}?token=${
      Config.COCKPIT_FORMS_API_TOKEN
      }`,
      { form: values }
    )
    .then(response => response.status)
    .catch(error => {
      console.error(error);
      return false;
    });
}

export async function getPageFromStorage(slug, callback) {
  setPageFromStorage(slug, (data) => {
    callback(data);
  })

  if (localStorage.getItem(localStoragePagesKey) && JSON.parse(localStorage.getItem(localStoragePagesKey))[slug]) {
    callback(JSON.parse(localStorage.getItem(localStoragePagesKey))[slug]);
  }
}

export async function setPageFromStorage(slug, callback) {
  fetchCollectionBySlug('page', slug).then(pages => {
    if (pages.data.length > 0) {
      let arr = localStorage.getItem(localStoragePagesKey) ? JSON.parse(localStorage.getItem(localStoragePagesKey)) : {};
      arr[slug] = pages.data[0];
      localStorage.setItem(localStoragePagesKey, JSON.stringify(arr));
      return callback(pages.data[0]);
    } else {
      callback('error');
    }
  });
}