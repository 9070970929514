import React, {  } from 'react';
import './Calculator.scss';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../hooks/useAuth';
import i18n from '../../../components/i18n';
import { StartListeningSocket } from '../../../hooks/helper';

const Calculator = ({ currentAsset, dataMarket }) => {
    const auth = useAuth();
    const { register, errors, watch } = useForm();
    const symbol = currentAsset.symbol;
    const leverage = [1,3,5,10,15,20,30];
    const terminationLeverage = {
        1: 100,
        3: 33.3,
        5: 20,
        10: 10,
        15: 7.5,
        20: 5,
        30: 3.3
    };

    if (auth.info && auth.info.user) StartListeningSocket(auth.info.user.token);


    const terminationPrice = () => {
        const marketPrice = Number.parseFloat(dataMarket[symbol].buy);
        const leverage = terminationLeverage[watch('leverage')] / 100;
        return (marketPrice - (marketPrice * leverage)).toFixed(2);
    }

    const percents = () => {
        const marketPrice = Number.parseFloat(dataMarket[symbol].buy);
        const percents = watch('percents') / 100;
        return (marketPrice + (marketPrice * percents)).toFixed(2);
    }

    const calculate = (marketPrice = dataMarket[symbol].buy) => {
        const amount = watch('amount');
        const leverage = watch('leverage');

        return Number((Number.parseFloat(marketPrice) * amount) / leverage);
    }

    const profit = () => {
        const leverage = watch('leverage');
        return Number((calculate(percents()) - calculate()) * leverage).toFixed(2);
    }

    return (
        <div className="calculator">
            <div className="card">
                <form onSubmit={e => e.preventDefault()}>
                    <div className="wrapperContentSidebar">
                        <div className="title">{i18n.t('platform.calculator')} <span>{currentAsset.name}</span></div>

                        <div className='d-flex py-2'>
                            <label>{i18n.t('platform.market_price')}</label>
                            <div className="wrapper-amount">
                                {dataMarket[symbol] ? Number.parseFloat(dataMarket[symbol].buy).toFixed(2) : 0}
                            </div>
                        </div>

                        <div className='d-flex align-items-baseline'>
                            <label>{i18n.t('platform.order')}</label>
                            <div className="wrapper-amount">
                                <input type="number" step="0.01" name="amount" ref={register({ min: 1 })} placeholder="0.00" />
                            </div>
                        </div>

                        <div className="leverageOuterContainer">
                            <div className="leverageInnerContainer">
                                {leverage.map((x, i) => {
                                    return <label key={i} className={`radioContainer`}>
                                        <input type="radio" name="leverage" value={x} ref={register({ required: true })} defaultChecked={(i === 0) ? true : false} />
                                        <span className="checkmark">{x}</span>
                                    </label>
                                })}
                            </div>
                        </div>

                        <div className='d-flex mt-2 py-2'>
                            <label>{i18n.t('platform.amount_to_invest')}</label>
                            <div className="wrapper-amount">
                                {dataMarket[symbol] ? calculate().toFixed(2) : 0}
                            </div>
                        </div>

                        <div className='d-flex align-items-baseline'>
                            <label>{i18n.t('platform.percents')}</label>
                            <div className="wrapper-amount">
                                <input type="number" step="0.01" name="percents" ref={register({ min: 1 })} placeholder="0.00" className='mb-0' />
                            </div>
                        </div>

                        <div className='d-flex mt-2 py-2'>
                            <label>{i18n.t('platform.target_price')}</label>
                            <div className="wrapper-amount">
                                {dataMarket[symbol] ? percents() : 0}
                            </div>
                        </div>

                        <div className='d-flex mt-2 py-2'>
                            <label>{i18n.t('platform.terminationPrice')}</label>
                            <div className="wrapper-amount">
                                {dataMarket[symbol] ? terminationPrice() : 0}
                            </div>
                        </div>

                        <div className='d-flex mt-2 py-2'>
                            <label>{i18n.t('platform.profit')}</label>
                            <div className="wrapper-amount">
                                {dataMarket[symbol] ? profit() : 0}
                            </div>
                        </div>

                        {errors.exampleRequired && <span>{i18n.t('platform.this_field_is_required')}</span>}
                    </div>
                </form>
            </div>
        </div >
    );
}

export default Calculator