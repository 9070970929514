import * as Config from "../config";
import axios from "axios";
import { useAuth } from "./useAuth";
import { useGet, createFormAndSubmit } from "./helper";
import { parsePhoneNumber } from 'react-phone-number-input'
import { sha256 } from 'js-sha256';


export const useTransactions = () => {
    const is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const auth = useAuth();
    const get = useGet();

    const getAll = () => {
        return axios.get(`${Config.ROUTER_HOST}/api/transactions/get/${auth.info.user.token}`, { headers: { "Authorization": `Bearer ${auth.info.access_token}` } });
    }

    const create = (data, setValue, setLoading?, setThankYou?, closeModal?, methods?) => {
        let request = JSON.parse(JSON.stringify(data));
        request.token = auth.info.user.token;

        request.method = methods[data.method].id;

        return axios.post(`${Config.ROUTER_HOST}/api/transactions/create`, request, { headers: { "Authorization": `Bearer ${auth.info.access_token}` } })
            .then(x => {
                if (x.data.data.status === 'success') {

                    if (data.method === 'bank' || data.method === 'withdraw') {  //Bank transfer or withdraw
                        setValue('amount', '');
                        setLoading(false);
                        setThankYou(true);
                        setTimeout(() => {
                            closeModal();
                        }, 1500)
                    }

                    if (data.method === 'cashier' && document.getElementById("cashier")) {
                        cashier(x, data, setLoading, closeModal);
                    }

                    if (data.method === 'bitcobrokers') {
                        bitcoBrokers(setLoading, closeModal);
                    }

                    if (data.method === 'venus') {
                        venus(setLoading, closeModal);
                    }

                    if (data.method === 'neobanq') {
                        neoBanq(x, data);
                    }

                    if (data.method === 'cidrus') {
                        cidrus(x, data);
                    }

                    if (data.method === 'paymaxis') {
                        paymaxis(x, data);
                    }

                    if (data.method === 'iPayTotal') {
                        iPayTotal(x, data);
                    }

                    if (data.method === 'titan') {
                        titanPayments(setLoading, closeModal);
                    }

                    if (data.method === 'ikajo') {
                        Ikajo(x, data);
                    }

                    if (data.method === 'PayWay') {
                        PayWay(x, data);
                    }

                    if (data.method === 'chargeMoney') {
                        chargeMoney(x, data);
                    }

                    if (data.method === 'paypound') {
                        paypound(x, data);
                    }

                    if (data.method === 'cashonex') {
                        cashonex(x, data);
                    }
                }
            });
    }

    const cashier = (x, data, setLoading, closeModal) => {
        document.getElementById("cashier").innerHTML = "";
        const script = document.createElement('script');
        script.src = Config.CASHIER_URL;
        script.async = true;
        script.setAttribute('data-cashier-key', Config.CASHIER_KEY);
        script.setAttribute('data-cashier-token', '');
        script.setAttribute('data-order-id', x.data.data.transaction_id);
        script.setAttribute('data-currency', Config.CURRENCY);
        script.setAttribute('data-direct-payment-method', '');
        script.setAttribute('data-currency-lock', 'true');
        script.setAttribute('data-amount-lock', 'true');
        script.setAttribute('data-first-name', auth.info.user.first_name);
        script.setAttribute('data-last-name', auth.info.user.last_name);
        script.setAttribute('data-amount', data.amount);
        script.setAttribute('data-email', auth.info.user.email);
        script.setAttribute('data-address', auth.info.user.street);
        script.setAttribute('data-country', 'de');
        script.setAttribute('data-state', '');
        script.setAttribute('data-theme', 'dark');
        script.setAttribute('data-city', auth.info.user.city);
        script.setAttribute('data-zip-code', auth.info.user.zip_code);
        script.setAttribute('data-phone', auth.info.user.phone);
        script.setAttribute('data-language', 'en');
        script.setAttribute('data-affiliate-id', '');
        script.setAttribute('data-tracking-id', '');
        script.setAttribute('data-platform-id', '');
        script.setAttribute('data-button-mode', 'modal');
        script.setAttribute('data-button-text', '');
        document.getElementById("cashier")!.appendChild(script);
        setTimeout(() => {
            let button: HTMLElement = document.getElementsByClassName('bp-cashier-button')[0] as HTMLElement;
            clickCashierButton(setLoading, closeModal, button);
        }, 2000);
    }

    const clickCashierButton = (setLoading, closeModal, button) => {
        if (button) {
            button.click();
            setLoading(false);
            closeModal();
        } else {
            clickCashierButton(setLoading, closeModal, button)
        }
    }

    const venus = (setLoading, closeModal) => {
        const url = Config.VENUS_URL;
        window.open(url, '_blank');
        if (is_safari) {
            window.location.href = url;
        }
        setLoading(false);
        closeModal(false);

    }

    const bitcoBrokers = (setLoading, closeModal) => {
        const url = Config.BITCOBROKERS_URL;
        window.open(url, '_blank');
        if (is_safari) {
            window.location.href = url;
        }
        setLoading(false);
        closeModal(false);

    }

    const neoBanq = (x, data) => {
        const user = auth.info.user;
        let MC = (get.countries) ? get.countries.filter(data => data.id === user.country_id) : '';
        MC = (MC.length > 0) ? MC[0].alpha2 : '';

        const request = {
            'api_key': Config.NEOBANQ_KEY,
            'first_name': auth.info.user.first_name,
            'last_name': auth.info.user.last_name,
            'address': auth.info.user.street,
            'country': MC,
            'state': auth.info.user.state,
            'city': auth.info.user.city,
            'zip': auth.info.user.zip_code,
            'email': auth.info.user.email,
            'phone_no': auth.info.user.phone,
            'amount': data.amount,
            'currency': Config.CURRENCY,
            'sulte_apt_no': x.data.data.transaction_id,
            'response_url': Config.WEBSITE_SITE_ROOT,
        };

        if(Config.NEOBANQ_CALL_BACK){
            request['webhook_url'] = Config.NEOBANQ_CALL_BACK;
        }

        return axios.post(Config.NEOBANQ_URL, request)
            .then(x => {
                if(x.data.redirect_3ds_url) {
                    window.location.href = x.data.redirect_3ds_url;
                }
            });
    }

    const titanPayments = (setLoading, closeModal) => {
        const url = Config.TITAN_URL;
        window.open(url, '_blank');
        if (is_safari) {
            window.location.href = url;
        }
        setLoading(false);
        closeModal(false);

    }

    const cidrus = (x, data) => {
        const user = auth.info.user;
        let MC = (get.countries) ? get.countries.filter(data => data.id === user.country_id) : '';
        MC = (MC.length > 0) ? MC[0].alpha2 : '';

        const request = {
            'api_key': Config.CIDRUS_KEY,
            'first_name': auth.info.user.first_name,
            'last_name': auth.info.user.last_name,
            'address': auth.info.user.street,
            'country': MC,
            'state': auth.info.user.state,
            'city': auth.info.user.city,
            'zip': auth.info.user.zip_code,
            'email': auth.info.user.email,
            'phone_no': auth.info.user.phone,
            'amount': data.amount,
            'currency': Config.CURRENCY,
            'customer_order_id': x.data.data.transaction_id,
            'response_url': Config.WEBSITE_SITE_ROOT,
            'webhook_url': `${Config.ROUTER_HOST}/api/transactions/update/cidrus`
        };

        return axios.post(Config.CIDRUS_URL, request)
            .then(x => {
                if(x.data.redirect_3ds_url) {
                    window.location.href = x.data.redirect_3ds_url;
                }
            });
    }

    const paymaxis = (x, data) => {
        const user = auth.info.user;
        let MC = (get.countries) ? get.countries.filter(data => data.id === user.country_id) : '';
        MC = (MC.length > 0) ? MC[0].alpha2 : '';
        const uniq = Math.floor(Date.now() + Math.random());

        const request = {
            'Credentials': {
                'MerchantId': Config.PAYMAXIS_ID,
                'Signature': sha256(uniq + Config.PAYMAXIS_KEY)
            },
            'CustomerDetails': {
                'FirstName': auth.info.user.first_name,
                'LastName': auth.info.user.last_name,
                'Phone': auth.info.user.phone,
                'Email': auth.info.user.email,
                'Street': auth.info.user.street,
                'City': auth.info.user.city,
                'Region': auth.info.user.state,
                'Country': MC,
                'Zip': auth.info.user.zip_code,
                'CustomerIP': get.userInfo.ip ? get.userInfo.ip : '127.0.0.1'
            },
            'ProductDescription': 'Deposit',
            'TotalAmount': data.amount*100,
            'CurrencyCode': Config.CURRENCY,
            'TransactionId': uniq,
            'CallbackURL': `${Config.ROUTER_HOST}/api/transaction/update/paymaxis`,
            'ReturnUrl': Config.WEBSITE_SITE_ROOT,
            'Custom': `transaction_id=${x.data.data.transaction_id}`
        };

        return axios.post(`${Config.ROUTER_HOST}/api/transaction/send/paymaxis`, request, { headers: { "authorization": `Basic ${Config.PAYMAXIS_TOKEN}` } })
            .then(x => {
                console.log(x);
                if(x.data.SecurePage) {
                    window.location.href = x.data.SecurePage;
                }
            });
    }

    const iPayTotal = (x, data) => {
        const user = auth.info.user;
        let MC = (get.countries) ? get.countries.filter(data => data.id === user.country_id) : '';
        MC = (MC.length > 0) ? MC[0].alpha2 : '';

        const request = {
            'api_key': Config.IPAYTOTAL_KEY,
            'first_name': auth.info.user.first_name,
            'last_name': auth.info.user.last_name,
            'address': auth.info.user.street,
            'country': MC,
            'state': auth.info.user.state,
            'city': auth.info.user.city,
            'zip': auth.info.user.zip_code,
            'email': auth.info.user.email,
            'phone_no': auth.info.user.phone,
            'amount': data.amount,
            'currency': Config.CURRENCY,
            'ip_address': get.userInfo.ip ? get.userInfo.ip : '127.0.0.1',
            'sulte_apt_no': x.data.data.transaction_id,
            'response_url': Config.WEBSITE_SITE_ROOT,
            'webhook_url': `${Config.ROUTER_HOST}/api/transaction/update/iPayTotal`
        };

        return axios.post(`${Config.ROUTER_HOST}/api/transaction/send/iPayTotal`, request)
            .then(x => {
                if(x.data.payment_redirect_url) {
                    window.location.href = x.data.payment_redirect_url;
                }
            });
    }

    const Ikajo = (x, data) => {
        const user = auth.info.user;
        let MC = (get.countries) ? get.countries.filter(data => data.id === user.country_id) : '';
        MC = (MC.length > 0) ? MC[0].alpha2 : '';

        const request = {
            'key': Config.IKAJO_KEY,
            'payment': 'CC',
            'order': x.data.data.transaction_id,
            'first_name': auth.info.user.first_name,
            'last_name': auth.info.user.last_name,
            'address': auth.info.user.street,
            'country': MC,
            'state': auth.info.user.state,
            'city': auth.info.user.city,
            'zip': auth.info.user.zip_code,
            'email': auth.info.user.email,
            'phone': auth.info.user.phone,
            'amount': parseInt(data.amount).toFixed(2),
            'url': Config.WEBSITE_SITE_ROOT,
            'ext1': Config.APP_NAME,
        };


        return axios.post(`${Config.ROUTER_HOST}/api/transaction/send/Ikajo`, request)
            .then(x => {
                console.log(x);
                request['sign'] = x.data.data.sign;
                request['data'] = x.data.data.data;
                createFormAndSubmit(request, Config.IKAJO_URL);
            });
    }


    const PayWay = (x, data) => {
        const user = auth.info.user;
        let MC = (get.countries) ? get.countries.filter(data => data.id === user.country_id) : '';
        MC = (MC.length > 0) ? MC[0].alpha2 : '';

        const request = {
            'merchantID': Config.PAYWAY_ID,
            'PersonalHashKey': Config.PAYWAY_KEY,
            'first_name': auth.info.user.first_name,
            'last_name': auth.info.user.last_name,
            'address': auth.info.user.street,
            'country': MC,
            'state': auth.info.user.state,
            'city': auth.info.user.city,
            'zip': auth.info.user.zip_code,
            'email': auth.info.user.email,
            'phone_no': auth.info.user.phone,
            'amount': data.amount,
            'currency': Config.CURRENCY,
            'ip_address': get.userInfo.ip ? get.userInfo.ip : '127.0.0.1',
            'transaction_id': x.data.data.transaction_id,
            'url_redirect': Config.WEBSITE_SITE_ROOT,
            'notification_url': `${Config.ROUTER_HOST}/api/transaction/update/PayWay`
        };

        return axios.post(`${Config.ROUTER_HOST}/api/transaction/send/PayWay`, request)
            .then(x => {
                if(x.data.data.url) {
                    window.location.href = x.data.data.url;
                }
            });
    }

    const chargeMoney = (x, data) => {
        const user = auth.info.user;
        let MC = (get.countries) ? get.countries.filter(data => data.id === user.country_id) : '';
        MC = (MC.length > 0) ? MC[0].alpha2 : '';

        const request = {
            'api_key': Config.CHARGE_MONEY_KEY,
            'first_name': auth.info.user.first_name,
            'last_name': auth.info.user.last_name,
            'address': auth.info.user.street,
            'country': MC,
            'state': auth.info.user.state,
            'city': auth.info.user.city,
            'zip': auth.info.user.zip_code,
            'ip_address': get.userInfo.ip ? get.userInfo.ip : '127.0.0.1',
            'email': auth.info.user.email,
            'phone_no': auth.info.user.phone,
            'amount': data.amount,
            'currency': Config.CURRENCY,
            'customer_order_id': x.data.data.transaction_id,
            'response_url': Config.WEBSITE_SITE_ROOT,
            'webhook_url': `${Config.ROUTER_HOST}/api/transaction/update/chargeMoney`
        };

        return axios.post(Config.CHARGE_MONEY_URL, request, {headers: { "Access-Control-Allow-Origin": "*" }})
            .then(x => {
                if(x.data.redirect_3ds_url) {
                    window.location.href = x.data.redirect_3ds_url;
                }
            });
    }

    const paypound = (x, data) => {
        const user = auth.info.user;
        let MC = (get.countries) ? get.countries.filter(data => data.id === user.country_id) : '';
        MC = (MC.length > 0) ? MC[0].alpha2 : '';

        const request = {
            'api_key': Config.PAYPOUND_KEY,
            'first_name': auth.info.user.first_name,
            'last_name': auth.info.user.last_name,
            'address': auth.info.user.street,
            'country': MC,
            'state': auth.info.user.state,
            'city': auth.info.user.city,
            'zip': auth.info.user.zip_code,
            'ip_address': get.userInfo.ip ? get.userInfo.ip : '127.0.0.1',
            'email': auth.info.user.email,
            'phone_no': auth.info.user.phone,
            'amount': data.amount,
            'currency': Config.CURRENCY,
            'customer_order_id': x.data.data.transaction_id,
            'response_url': Config.WEBSITE_SITE_ROOT,
            'webhook_url': `${Config.ROUTER_HOST}/api/transaction/update/paypound`
        };

        return axios.post(Config.PAYPOUND_URL, request, {headers: { "Access-Control-Allow-Origin": "*" }})
            .then(x => {
                if(x.data.redirect_3ds_url) {
                    window.location.href = x.data.redirect_3ds_url;
                }
            });
    }

    const cashonex = (x, data) => {
        const user = auth.info.user;
        let MC = (get.countries) ? get.countries.filter(data => data.id === user.country_id) : '';
        MC = (MC.length > 0) ? MC[0].alpha2 : '';

        const request = {
            'salt': Config.CASHONEX_SALT,
            'first_name': auth.info.user.first_name,
            'last_name': auth.info.user.last_name,
            'address': auth.info.user.street,
            'country': MC,
            'city': auth.info.user.city,
            'zip_code': auth.info.user.zip_code,
            'clientip': get.userInfo.ip ? get.userInfo.ip : '127.0.0.1',
            'email': auth.info.user.email,
            'phone': auth.info.user.phone,
            'amount': data.amount,
            'currency': Config.CURRENCY,
            'orderid': x.data.data.transaction_id,
            'redirect_url': Config.WEBSITE_SITE_ROOT,
            'webhook_url': `${Config.ROUTER_HOST}/api/transaction/update/cashonex`
        };

        return axios.post(Config.CASHONEX_URL, request, {headers: { "Access-Control-Allow-Origin": "*", "Idempotency-Key": Config.CASHONEX_KEY }})
            .then(x => {
                if(x.data.redirectUrl) {
                    window.location.href = x.data.redirectUrl;
                }
            });
    }
    return { getAll, create }
}