import React from "react";
import styled from "styled-components";
//
import * as Config from "../../config";

const Image = styled.img``;
export default (props) => {
  var setStyle = {};
  if (props.style) {
    props.style.split(';').map(item => {
      var sty = item.split(':')
      setStyle[sty[0]] = sty[1];
    });
  }

  var src = props.image ? props.image.path : props.src ? props.src : '';
  if (!/^(http)s?:\/\//i.test(src)) {
    src = `${Config.COCKPIT_HOST}/${src?.replace("//", "/").replace("//", "/")}`;
  }

  let alt = '';
  if (props.image && props.image.meta && props.image.meta.title) {
    alt = props.image.meta.title;
  }

  return (
    <Image
      src={src}
      alt={alt}
      title={alt}
      width={props.width}
      style={setStyle}
    />
  );
};
