import * as Config from "../config";
import axios from "axios";
import { LoginRequest, RegistrationRequest, ForgotPasswordRequest, UpdatePasswordRequest, uploadDocumentsRequest } from "./models";
import { useState, useEffect } from "react";
import { EventEmitter } from "./EventEmitter";
import { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'

export const useAuth = () => {
    const onLoginSuccess = new EventEmitter('login_success');
    const onUpdateSuccess = new EventEmitter('update_success');
    const loginStatusChange = new EventEmitter('login_status_change');
    const [info, setInfo] = useState(localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : null);
    const onError = new EventEmitter('login_error');
    const uploadSuccess = new EventEmitter('upload_success');
    const uploadError = new EventEmitter('upload_error');
    const showsPopups = new EventEmitter('show_popups');

    const isLoggedIn = (): boolean => {
        return (info !== null && info.accessToken !== null);
    };

    const checkLogin = () => {
        if (!isLoggedIn()) {
            window.location.href = '/login';
            return false;
        }
    }

    useEffect(() => {
        localStorage.setItem('auth', JSON.stringify(info));
    }, [info]);

    const missingDetails = () => {
        return (
            (!info.user.first_name || info.user.first_name.length === 0) ||
            (!info.user.last_name || info.user.last_name.length === 0) ||
            (!info.user.email || info.user.email.length === 0) ||
            (!info.user.phone || info.user.phone.length === 0 || !isValidPhoneNumber(info.user.phone) || !isPossiblePhoneNumber(info.user.phone)) ||
            (!info.user.birth_date || info.user.birth_date.length === 0) ||
            (!info.user.country_id || info.user.country_id.length === 0) ||
            (!info.user.state || info.user.state.length === 0) ||
            (!info.user.city || info.user.city.length === 0) ||
            (!info.user.street || info.user.street.length === 0) ||
            (!info.user.zip_code || info.user.zip_code.length === 0)
        );
    }

    const signOut = () => {
        setInfo(null);
        localStorage.removeItem('theme-mode');
        localStorage.removeItem('auth');
        loginStatusChange.emit(false);
        window.location.href = "/login";
    }

    const signIn = (loginRequest: LoginRequest) => {
        axios.post(`${Config.ROUTER_HOST}/api/accounts/login`, loginRequest).then(x => {
            if (x.data.data.access_token !== undefined && info === null && x.data.status === true) {
                setInfo(x.data.data);
                loginStatusChange.emit(true);
            } else {
                onError.emit(x.data);
            }
            return x;
        }).catch(e => onError.emit(e))
    }

    const forgotPassword = (ForgotRequest: ForgotPasswordRequest, setSent) => {
        axios.post(`${Config.ROUTER_HOST}/api/get/forgotPassword`, ForgotRequest).then(x => {
            if (x.data.status === true) {
                setSent(true);
            } else {
                //onError.emit(x.data);
            }
            return x;
        })//.catch(e => onError.emit(e))
    }

    const uploadDocuments = (data: uploadDocumentsRequest) => {
        const formData = new FormData();
        formData.append('files', data['files'][0]);
        formData.append('token', data['token']);
        formData.append('type', data['type']);

        axios.post(`${Config.ROUTER_HOST}/api/accounts/upload`, formData, { headers: { "Authorization": `Bearer ${info.access_token}`, 'Content-Type': 'multipart/form-data' } }).then(x => {
            if (x.data.status === true) {
                uploadSuccess.emit(true);
            } else {
                uploadError.emit(x.data);
            }
            return x;
        }).catch(e => {})
    }

    const updatePassword = (PasswordRequest: UpdatePasswordRequest) => {
        axios.post(`${Config.ROUTER_HOST}/api/accounts/updatePassword`, PasswordRequest, { headers: { "Authorization": `Bearer ${info.access_token}` } }).then(x => {
            if (x.data.status === true) {
                // setShowNoti(true);
            } else {
                onError.emit(x.data);
            }
            return x;
        }).catch(e => onError.emit(e))
    }

    const autoLogin = (code, userInfo) => {
        axios.post(`${Config.ROUTER_HOST}/api/accounts/login/${code}`, { info: userInfo }).then(x => {
            if (x.data.data.access_token !== undefined && info === null && x.data.status !== false) {
                setInfo(x.data.data);
                loginStatusChange.emit(true);
            } else {
                onError.emit(x.data);
            }
            return x;
        }).catch(e => onError.emit(e))
    }

    const signUp = (registrationRequest: RegistrationRequest) => {
        axios.post(`${Config.ROUTER_HOST}/api/accounts/register`, registrationRequest).then(x => {
            if (x.data.data.access_token !== undefined && info === null && x.data.status === true) {
                setInfo(x.data.data);
                loginStatusChange.emit(true);
            } else {
                onError.emit(x.data);
            }
            return x;
        }).catch(e => onError.emit(e))
    }

    const GetData = () => {
        useEffect(() => {
            if(!info || !info.user) return;
            let isCancelled = false;
            axios.post(`${Config.ROUTER_HOST}/api/accounts/get`, { token: info.user.token }, { headers: { "Authorization": `Bearer ${info.access_token}` } }).then(x => {
                if (x.data.data.access_token !== undefined) {
                    if (!isCancelled) {
                        popups();
                        setInfo(x.data.data);
                    }
                } else {
                    signOut();
                }
                // return x;
            }).catch(e => signOut())
            return () => {
                isCancelled = true;
            };
        }, []);
    }

    const update = (data) => {
        axios.post(`${Config.ROUTER_HOST}/api/accounts/update`, data, { headers: { "Authorization": `Bearer ${info.access_token}` } }).then(x => {
            setInfo(x.data.data);
            onUpdateSuccess.emit(true);
            return x;
        }).catch(e => console.log(e))
    }

    const autoTrading = (status) => {
        const request = { token: info.user.token, auto_trading: status }
        axios.post(`${Config.ROUTER_HOST}/api/accounts/autoTrading`, request, { headers: { "Authorization": `Bearer ${info.access_token}` } }).then(x => {
            setInfo({ ...info, ...x.data.data });
            return x;
        }).catch(e => console.log(e))
    }

    const popups = () => {
        axios.post(`${Config.ROUTER_HOST}/api/accounts/popups`, {token: info.user.token}, { headers: { "Authorization": `Bearer ${info.access_token}` } }).then(x => {
            if (x.data.status === true) {
                showsPopups.emit(x.data.data);
            }
            return x;
        });
    }

    const readedPopup = (id) => {
        axios.post(`${Config.ROUTER_HOST}/api/accounts/popup`, {token: info.user.token, id: id}, { headers: { "Authorization": `Bearer ${info.access_token}` } }).then(x => {
        });
    }

    const GetReferrals = (setAccounts) => {
        useEffect(() => {
            let isCancelled = false;
            axios.post(`${Config.ROUTER_HOST}/api/accounts/referrals`, { token: info.user.token }, { headers: { "Authorization": `Bearer ${info.access_token}` } }).then(x => {
                if (!isCancelled) {
                    setAccounts(x.data);
                }
            });
            return () => {
                isCancelled = true;
            };
        }, []);
    }

    const GetBalance = () => {
        return axios.post(`${Config.ROUTER_HOST}/api/accounts/getBalance`, { token: info.user.token }, { headers: { "Authorization": `Bearer ${info.access_token}` } });
    }

    return { signIn, autoLogin, isLoggedIn, checkLogin, loginStatusChange, updatePassword, onUpdateSuccess, onLoginSuccess, signOut, signUp, info, update, autoTrading, GetData, onError, forgotPassword, uploadDocuments, uploadSuccess, uploadError, missingDetails, showsPopups, readedPopup, GetReferrals, GetBalance }
}