import { useEffect, useState } from 'react';
import * as Config from "../config";
import { socketPrices, socketPositions } from './socketConnection';
import axios from "axios";
import { useAuth } from './useAuth';
import { useHistory } from 'react-router';

export const getLanguageForTradingview = (localStorage.getItem('i18nextLng') === 'de') ? 'de_DE' : localStorage.getItem('i18nextLng');

const themeMode = localStorage.getItem('theme-mode') ? localStorage.getItem('theme-mode') : 'dark';

export const MarketPrice = () => {
    const [getPrice, setPrice] = useState({});
    useEffect(() => {
        let isCancelledMarketPrice = false;
        socketPrices.on('price', (data) => {
            if (!isCancelledMarketPrice) {
                setPrice(data);
            }
        });
        return () => {
            isCancelledMarketPrice = true;
        };
    }, []);

    return { getPrice };
}

export const Formating = (amount, currency?) => {
    const showCurrency = (!currency) ? '' : `${currency} `;

    let maximumFractionDigits;
    if (amount && amount.toString().split(".")[0].replace("-", "") < 10) {
        maximumFractionDigits = 6;
    } else if (amount && amount.toString().split(".")[0].replace("-", "") < 100) {
        maximumFractionDigits = 4;
    } else {
        maximumFractionDigits = 2;
    }
    return showCurrency + new Intl.NumberFormat('us-EN', { currency: Config.CURRENCY, maximumFractionDigits: maximumFractionDigits }).format(amount);
}

export const StartListeningSocket = (token) => {
    useEffect(() => {
        let isCancelledStartListeningSocket = false;
        if (!isCancelledStartListeningSocket) {
            socketPositions.emit('positions', { token });
            socketPositions.emit('balance', { token });
            console.log("In useEffect");
            setInterval(()=>{
                console.log("In setInterval");
                socketPositions.emit('positions', { token });
            }, 1000*5)
        }

        return () => {
            isCancelledStartListeningSocket = true;
            socketPositions.emit('disconnect');
            socketPositions.off();
        };
    }, [token]);
}

export const GetBalance = () => {
    const dataMarket: any = MarketPrice().getPrice;
    const [summary, setSummary] = useState({ balance: null, equity: null, freeMargin: null, profit: null });
    const [positions, setPositions] = useState({ history: [], open: [] });
    let totalProfit = 0,
        totalAmount = 0,
        equity = 0,
        free_margin = 0,
        profit = 0;
    const [readyToDisplay, setReadyToDisplay] = useState(false);
    const auth = useAuth();

    useEffect(() => {
        let isCancelledBalance = false;
        auth.GetBalance().then(item=>{
            const data = item.data.data;
            if (!isCancelledBalance) {
                // console.log('Local', data)
                setSummary(data.balance);
                setPositions(data.positions);
                setReadyToDisplay(true);
            }
        });
        return () => {
            isCancelledBalance = true;
        };
    }, []);

    // useEffect(() => {
    //     let isCancelledBalance = false;
    //     socketPositions.on('balance', (item) => {
    //         console.log(item);
    //         if (!item.error && !isCancelledBalance) {
    //             setSummary(item);
    //             setReadyToDisplay(true);
    //         }
    //     });
    //     return () => {
    //         isCancelledBalance = true;
    //     };
    // }, []);

    useEffect(() => {
        let isCancelledPositions = false;
        let isCancelledBalance = false;
        socketPositions.on('balance', (item) => {
            if (!item.error && !isCancelledBalance) {
                // console.log(item);
                setSummary(item);
                setReadyToDisplay(true);
            }
        });

        socketPositions.on('positions', (item) => {
            if (!item.error && !isCancelledPositions) {
                // console.log(item);
                setPositions(item);
            }
        });
        return () => {
            isCancelledPositions = true;
            isCancelledBalance = true;
        };
    }, []);

    if (positions.open.length > 0) {
        positions.open.map((position) => {
            const profit = CalculateRealTimeProfit(position, dataMarket);
            totalProfit = (totalProfit + profit);
            totalAmount = (totalAmount + Number(position.amount));
        });
    }

    free_margin = summary.balance - totalAmount;
    profit = summary.profit;
    equity = summary.balance + totalProfit;

    return { summary, totalProfit, totalAmount, positions, equity, free_margin, profit, dataMarket };
}

export const TechnicalAnalysis = (symbol) => {
    useEffect(() => {
        if (document.getElementById("TechnicalAnalysis")) {
            document.getElementById("TechnicalAnalysis").innerHTML = "";
            const script = document.createElement('script');
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js'
            script.async = true;
            script.innerHTML = JSON.stringify({
                "interval": "1m",
                "width": "100%",
                "colorTheme": themeMode,
                "isTransparent": true,
                "height": "100%",
                "symbol": symbol,
                "showIntervalTabs": true,
                "locale": getLanguageForTradingview,
                "largeChartUrl": `${Config.WEBSITE_SITE_ROOT}/platform/${symbol}`
            });
            document.getElementById("TechnicalAnalysis")!.appendChild(script);
        }
    }, [symbol])
}

export const CalculateRealTimeProfit = (position, dataMarket) => {
    const symbol = position.symbol
    if ((dataMarket[symbol] && dataMarket[symbol].buy)) {
        const buyPrice = dataMarket[symbol].buy;
        const sellPrice = dataMarket[symbol].sell;
        if (position.direction === 'buy') {
            return (sellPrice - position.open_rate) * ((position.amount * position.leverage) / position.open_rate);
        } else {
            return (position.open_rate - buyPrice) * ((position.amount * position.leverage) / position.open_rate);
        }
    }
    return 0;
}

export const CalculateProfit = (position, price) => {
    if (position.direction === 'buy') {
        return ((price) - position.open_rate) * ((position.amount * position.leverage) / position.open_rate);
    } else {
        return (position.open_rate - (price)) * ((position.amount * position.leverage) / position.open_rate); //SellPriceFormula
    }
}

export const ReverseCalculateProfit = (position, price) => {
    if (position.direction === 'buy') {
        return (price * Number(position.open_rate)) / (position.amount * Number(position.leverage)) + Number(position.open_rate); //SellPriceFormula
    } else {
        return (price * Number(position.open_rate)) / (position.amount * Number(position.leverage)) - Number(position.open_rate); //SellPriceFormula
    }
}

export const SellPriceFormula = (price) => {
    return (price - (0.025 / 100) * price);
}


export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export const useGet = () => {
    const [assets, setAssets] = useState(null);
    const [assetsGroups, setAssetsGroups] = useState(null);
    const [countries, setCountries] = useState(localStorage.getItem('cou') ? JSON.parse(atob(localStorage.getItem('cou'))) : null);
    const [userInfo, setUserInfo] = useState(localStorage.getItem('uin') ? JSON.parse(atob(localStorage.getItem('uin'))) : null);
    const auth = useAuth();
    let history = useHistory();

    if (!localStorage.getItem('uin')) {
        axios.get('https://api.infoip.io')
            .then(data => {
                localStorage.setItem('uin', btoa(JSON.stringify(data.data)));
                setUserInfo(data.data);
            })
    }



    useEffect(() => {
        let isCancelledAssets = false;

        if (!isCancelledAssets && auth.info) {
            axios.get(`${Config.ROUTER_HOST}/api/get/assets?token=${auth.info.user.token}`, { headers: { "Authorization": `Bearer ${auth.info.access_token}` } })
                .then(asset => {
                    const group = [];
                    if (!isCancelledAssets) {
                        if (asset.data) {
                            asset.data.map(item=>{
                                if(!group[item.position_assets_category.name]) {
                                    group[item.position_assets_category.name] = [];
                                }
                                group[item.position_assets_category.name].push({name:item.name, symbol: item.symbol});
                            })
                            setAssetsGroups(group);
                            setAssets(asset.data);
                        } else {
                            auth.signOut();
                        }
                    }
                }).catch(error => {
                    auth.signOut();
                    history.push('/');
                });
            if (!localStorage.getItem('cou')) {
                axios.get(`${Config.ROUTER_HOST}/api/get/countries?token=${auth.info.user.token}`, { headers: { "Authorization": `Bearer ${auth.info.access_token}` } })
                    .then(countries => {
                        if (countries.data) {
                            localStorage.setItem('cou', btoa(JSON.stringify(countries.data)));
                            setCountries(countries.data);
                        } else {
                            auth.signOut();
                        }
                    }).catch(error => {
                        auth.signOut();
                        history.push('/');
                    });
            }

        }

        return () => {
            isCancelledAssets = true;
        }
    }, []);

    return { assets, assetsGroups, countries, userInfo };
}

export const checkDataMarket = (symbol, dataMarket) => {
    return symbol !== undefined && dataMarket && dataMarket[symbol] !== undefined && dataMarket[symbol].buy !== 0 && dataMarket[symbol].buy !== null && dataMarket[symbol].buy !== undefined;
}

export const createFormAndSubmit = (request, url) => {
    let form = document.createElement("form");
    form.method = "POST";
    form.action = url;

    let elements = {};
    Object.keys(request).map(key=>{
        elements[key] = document.createElement("input");
        elements[key].setAttribute('name',key);
        elements[key].setAttribute('value',request[key]);
        elements[key].setAttribute('type', 'text');
        form.appendChild(elements[key]);
    })

    document.body.appendChild(form);

    form.submit();
}

export const useWidgetEvents = () => {
    useEffect(() => {
        if (document.getElementById("embed-widget-events")) {
            const script = document.createElement('script');
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-events.js'
            script.async = true;
            script.innerHTML = JSON.stringify({
                "width": "100%",
                "height": "100%",
                "colorTheme": "light",
                "isTransparent": false,
                "locale": "en",
                "importanceFilter": "-1,0,1",
                "currencyFilter": "USD,EUR,ITL,NZD,CHF,AUD,FRF,JPY,ZAR,TRL,CAD,DEM,MXN,ESP,GBP"
              });
            document.getElementById("embed-widget-events")!.appendChild(script);
        }
    }, [document.getElementById("embed-widget-events")])

}