import React, { useState, useEffect } from 'react';
import './MyAccount.scss';
import { useAuth } from '../../hooks/useAuth';
import { Link } from "react-router-dom";
import i18n from '../../components/i18n';
import { Row, Col } from 'react-bootstrap';
import { useGet } from "../../hooks/helper";
import { Notification } from '../../components/Notification/Notification';
import InfoTab from './Info';
import PasswordTab from './Password';
import DocumentsTab from './Documents';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';

export const MyAccount = () => {
    const { page } = useParams();
    const auth = useAuth();
    auth.checkLogin();
    const get = useGet();
    const countries = get.countries;
    const [showNoti, setShowNoti] = useState(false);
    const [myCountry, setMyCounty] = useState();
    const pages = ['info', 'password', 'documents'];
    const [tab, setTab] = useState((pages.includes(page)) ? page : 'info');


    useEffect(() => {
        let isCancelled = false;

        if (!isCancelled) {
            let MC = (countries) ? countries.filter(data => data.id === auth.info.user.country_id) : '';
            MC = (MC.length > 0) ? MC[0].name : '';
            setMyCounty(MC);
        }

        return () => {
            isCancelled = true;
        }
    }, [countries]);
    return (
        <div className="my-account sec-pad">
            <Row>
                <Col xs="12" md="4" className="userInformation mr-auto">
                    <h1>{i18n.t('myAccount.title')}</h1>
                    <div className="avatar">
                        <FontAwesomeIcon icon={faUserTie} />
                    </div>
                    <h2 className="name">{auth.info.user.first_name} {auth.info.user.last_name}</h2>
                    <div className="email"><strong>Email:</strong>{auth.info.user.email}</div>
                    <div className="location"><strong>Location:</strong>{myCountry}</div>
                    <div className="joined"><strong>Joined at:</strong>{auth.info.user.created_at}</div>
                    <div className="joined"><strong>Status:</strong>{auth.info.user.verified_account ? 'Verified' : 'Not Verified'}</div>
                    <div className="joined"><strong>Level:</strong>{auth.info.user.level}</div>
                    <div className="joined"><strong>PIN:</strong>{auth.info.user.pin ? auth.info.user.pin : 'NONE'}</div>
                </Col>
                <Col xs="12" md="8">
                    <ul className="tabs">
                        {pages.map((item, i) => {
                            return <li key={i} className={tab === item ? 'active' : ''}><Link to={`/my-account/${item}`} onClick={() => setTab(item)}>{i18n.t(`myAccount.tabs.${item}`)}</Link></li>
                        })}
                    </ul>
                    {tab === 'info' && <InfoTab setShowNoti={setShowNoti} />}
                    {tab === 'password' && <PasswordTab setShowNoti={setShowNoti} />}
                    {tab === 'documents' && <DocumentsTab setShowNoti={setShowNoti} />}
                </Col>
            </Row>
            <Notification show={showNoti} setShow={setShowNoti} title="Alert" text="The data was successfully saved" />
        </div>
    );
}