import React, { useState, useEffect } from 'react';
import './Login.scss'
import { useAuth } from '../../hooks/useAuth';
import i18n from '../../components/i18n';
import { useParams } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { useGet } from '../../hooks/helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

let isCancelled = { button: false, checking: false };

const AutoLogin = () => {
    const auth = useAuth();
    const { code } = useParams();
    const [error, setError] = useState(false);
    const [spinner, setSpinner] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const get = useGet();

    useEffect(() => {
        if (!isCancelled.button) {
            setTimeout(() => {
                let button: HTMLElement = document.querySelectorAll('#loginButton')[0] as HTMLElement;
                if(button) button.click();
            }, 2000);
        }
        return () => {
            isCancelled.button = true;
        }
    }, [code])

    useEffect(() => {
        if (auth.info && !isCancelled.checking) {
            if (auth.missingDetails()) {
                localStorage.setItem('register', 'true');
                window.location.href = '/my-account/info';
            } else {
                window.location.href = '/platform/BTCUSD';
            }
        }
        return () => {
            isCancelled.button = true;
        }
    }, [auth.info])

    const onSubmit = () => {
        setLoadingButton(true);
        auth.autoLogin(code, get.userInfo);
        auth.onError.subscribe(x => {
            if (!x.status) {
                setLoadingButton(false);
                setError(true);
                setSpinner(false);
            }
        })
    }


    return (
        <>
            <div className="login autoLogin thm-container sec-pad">
                <h1>{i18n.t('login.auto.title')}</h1>
                <h2>{i18n.t('login.auto.text')}</h2>
                {spinner && <div className="spinner"><FontAwesomeIcon icon={faSpinner} spin /></div>}
                <Button onClick={() => onSubmit()} id="loginButton" className={`button ${loadingButton ? 'loading' : ''}`} disabled={loadingButton} type="submit"></Button>
                {error && <div className="error">{i18n.t('login.auto.error')}</div>}
            </div>
        </>
    );
}

export default AutoLogin;