import React, { useState } from "react";
import "./SLTP.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { CalculateProfit, Formating } from "../../../hooks/helper";
import { useAuth } from "../../../hooks/useAuth";
import i18n from "../../../components/i18n";
import { Row, Col, Button } from "react-bootstrap";
import { usePositions } from "../../../hooks/usePositions";
import * as Config from "../../../config";

export const SLTP = ({ position, setCurrentPosition, modalIsVisible, setModalIsVisible, dataMarket }) => {
  const auth = useAuth();
  const usePosition = usePositions();
  const [profit, setProfit] = useState(position.take_profit);
  const [loss, setLoss] = useState(position.stop_lost);
  const takeProfit = isNaN(Number.parseFloat(profit)) ? null : Number.parseFloat(profit);
  const stopLoss = isNaN(Number.parseFloat(loss)) ? null : Number.parseFloat(loss);
  const BuyingPrice = Number.parseFloat(dataMarket[position.symbol].buy);
  const SellingPrice = Number.parseFloat(dataMarket[position.symbol].sell);


  const closeModal = () => {
    setModalIsVisible(false);
    setCurrentPosition(null);
  };

  const displayProfit = (profit) => {
    let result = Formating(CalculateProfit(position, profit));
    return result === "NaN" ? 0 : result;
  };

  const displayLoss = (loss) => {
    let result = Formating(CalculateProfit(position, loss));
    return result === "NaN" ? 0 : result;
  };

  const updatePosition = () => {
    if(
        (position.direction === "buy" &&
        (takeProfit === null || takeProfit > SellingPrice) &&
        (stopLoss === null || stopLoss < SellingPrice)
        )
      || 
        (position.direction === "sell" &&
        (takeProfit === null || takeProfit < BuyingPrice) &&
        (stopLoss === null || stopLoss > BuyingPrice)
        )
      ){
      usePosition.UpdateSLTP(auth.info, position.id, stopLoss, takeProfit);
      closeModal();
    }
  };

  window.addEventListener("keydown", (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  });
  return (
    <>
      {modalIsVisible && (
        <>
          <div className="sltp-bg" onClick={() => closeModal()}></div>
          <div className="sltp-modal">
            <div className="title">
              {position.symbolName} {position.direction} {i18n.t("platform.modal.position")}
              <span onClick={() => closeModal()}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </div>
            <form>
              <label>{i18n.t("platform.modal.take_profit")}</label>
              <Row className="d-flex">
                <Col className="wrapper-input" data-currency={Config.CURRENCY_SYMBOL}>
                  <input
                    type="number"
                    step="0.01"
                    name="profit"
                    defaultValue={isNaN(profit) ? 0 : profit}
                    onChange={(e) => setProfit(Number.parseFloat(e.target.value))}
                    placeholder="0.00"
                  />
                </Col>
              </Row>
              <Row>
                <Col>{i18n.t("platform.modal.profit_amount")}</Col>
                <Col className="text-right">{Config.CURRENCY_SYMBOL} {profit ? displayProfit(profit) : 0}</Col>
              </Row>
              <div className="error">
                {position.direction === "buy" && takeProfit < SellingPrice ? `Can't be lower than ${Formating(SellingPrice)}` : ``}
                {position.direction === "sell" && takeProfit > BuyingPrice ? `Can't be higher than ${Formating(BuyingPrice)}` : ``}
              </div>

              <label>{i18n.t("platform.modal.stop_lost")}</label>
              <Row className="d-flex">
                <Col className="wrapper-input">
                  <input
                    type="number"
                    step="0.01"
                    name="lost"
                    defaultValue={isNaN(loss) ? 0 : loss}
                    onChange={(e) => setLoss(Number.parseFloat(e.target.value))}
                    placeholder="0.00"
                  />
                </Col>
              </Row>
              <Row>
                <Col>{i18n.t("platform.modal.profit_amount")}</Col>
                <Col className="text-right">{Config.CURRENCY_SYMBOL} {loss ? displayLoss(loss) : 0}</Col>
              </Row>
              <div className="error">
                {position.direction === "buy" && stopLoss > SellingPrice ? `Can't be higher than ${Formating(SellingPrice)}` : ``}
                {position.direction === "sell" && stopLoss < BuyingPrice ? `Can't be lower than ${Formating(BuyingPrice)}` : ``}
              </div>

              <div className="footer">
                <Button
                  onClick={() => {
                    updatePosition();
                  }}
                >
                  {i18n.t("platform.modal.update_button")}
                </Button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};
