import { socketPositions } from './socketConnection';

export const usePositions = () => {

    const OpenPosition = (dataUser, direction, { amount, leverage }, lastPrice, symbol) => {
        let price = Number.parseFloat(lastPrice);

        const SendData = {
            dataUser, direction, amount, leverage, price, symbol
        };
        socketPositions.emit('openPosition', { SendData });
    }

    const ClosePosition = (dataUser, id, price) => {
        const data = { dataUser, id, price };
        socketPositions.emit('closePosition', { data });
    }

    const UpdateSLTP = (dataUser, id, stop_lost, take_profit) => {
        const data = { dataUser, id, stop_lost, take_profit };
        socketPositions.emit('updatePosition', { data });
    }



    return { OpenPosition, ClosePosition, UpdateSLTP }
}