import React, { useState, useEffect } from "react";
import "./Referrals.scss";
import i18n from "../../components/i18n";
import { Table } from "react-bootstrap";
import {useAuth} from "../../hooks/useAuth";
import {Formating} from "../../hooks/helper";
import * as Config from "../../config";

const Referrals = () => {
  const [accounts, setAccounts] = useState(null);
  const auth = useAuth();
  auth.checkLogin();
  auth.GetReferrals(setAccounts);

  const clickOnLink = (event) => {
    event.target.select();
  };

  const NoData = () => {
    return (
      <tr>
        <td colSpan={4}>{i18n.t("transactions.no_data_to_show")}.</td>
      </tr>
    );
  };
  
  return (
    <div className="referrals sec-pad thm-container">
      <div className="header-referrals">
        <h1>Referrals</h1>

        <div className="wrapper-link">
          Referral link
          <input
            className="link"
            defaultValue={`${Config.WEBSITE_SITE_ROOT}/?ref=${auth.info.user.uniqid}`}
            onFocus={(e) => clickOnLink(e)}
            onClick={(e) => clickOnLink(e)}
          />
        </div>
      </div>
      <div className="card">
        <Table hover responsive>
          <thead>
            <tr>
              <th>{i18n.t("platform.account")}</th>
              <th>{i18n.t("platform.email")}</th>
              <th>{i18n.t("platform.date")}</th>
            </tr>
          </thead>
          <tbody>
            {(accounts && accounts.length > 0) ? (
              <>
                {accounts.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.full_name} ***</td>
                      <td>{item.email}</td>
                      <td>{item.created_at}</td>
                    </tr>
                  );
                })}
              </>
            ) : (<NoData />)}
          </tbody>
        </Table>


      </div>
    </div>
  );
};

export default Referrals;
