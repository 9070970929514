import io from 'socket.io-client';
import * as Config from "../config";


export const useConnect = () => {
    let auth = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : null;
    var query;
    
    if (auth === null) {
        const socketPositions = null,
            socketPrices = null;
        return { socketPositions, socketPrices };
    } else {
        query = `token=${auth.user.token}`
        const socketPositions = io(Config.ROUTER_HOST, { 'query': query });
        const socketPrices = io(Config.PRICING_HOST);
        return { socketPositions, socketPrices };
    }
}

const { socketPositions, socketPrices } = useConnect();

export { socketPositions, socketPrices };