import React from "react";
import { Container } from "reactstrap";
import styled from "styled-components";

const ContainerStyled = styled(Container)`
  padding: 1rem 15px;
`;

export default (props) => {
  const Tag = styled(props.tag)`padding: 1rem 15px;`;
  const content = props?.text?.replace(/APP_NAME/g, process.env.REACT_APP_NAME);

  return (
    <Tag className={[`component--${props.name}`, props.class]} style={props.setStyle} id={props.id}>
      {content}
    </Tag>
  );
};
