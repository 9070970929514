import React, { useEffect, useState } from "react";
import './EuroKrypto.scss';
import * as Config from "../../config";
import { getPageFromStorage } from "../../cockpit/fetch";
import PageComponents from "../../components/cockpit/PageComponents";

const Index = () => {
    const [page, setPage] = useState(null);

    useEffect(() => {
        let isCancelled = false;

        getPageFromStorage('index', (data) => {
            if (!isCancelled) {
                setPage(data);
            }
        });

        return () => {
            isCancelled = true;
        };
    }, [])

    useEffect(() => {
        if (document.getElementById("chart")) {
            const script = document.createElement('script');
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js'
            script.async = true;
            script.innerHTML = JSON.stringify({
                "width": "100%",
                "height": 400,
                "currencies": [
                  "EUR",
                  "USD",
                  "JPY",
                  "GBP",
                  "CHF",
                  "AUD",
                  "CAD",
                  "NZD"
                ],
                "isTransparent": false,
                "colorTheme": "light",
                "locale": "en"
              });
            document.getElementById("chart")!.appendChild(script);
        }
    }, [document.getElementById("chart")])

return <>
        <div className="home-page">
            {page &&
                <PageComponents components={page.components || []} />
            }
        </div>
    </>
}
export default Index;
