import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import App from './App';
import * as Config from "./config";
import TagManager from 'react-gtm-module'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

//Trd: #f7b331
//Pro: #0098db
//Nxi: #143a70

document.documentElement.style.setProperty('--base', `#${Config.APP_COLOR}`);
document.documentElement.style.setProperty('--logo', `url(assets/img/${Config.APP_NAME}/logo.png)`);
document.documentElement.style.setProperty('--logo-day', `url(assets/img/${Config.APP_NAME}/logo-day.png)`);

document.documentElement.classList.add(Config.APP_NAME);

if (localStorage.getItem('theme-mode') && localStorage.getItem('theme-mode') === 'dark') {
    document.documentElement.setAttribute('color-mode','dark');
} else {
    document.documentElement.setAttribute('color-mode','light');
}
const tagManagerArgs = {
    gtmId: Config.GTM_ID
}

TagManager.initialize(tagManagerArgs)

const rootNode = document.querySelector('#root')

ReactDOM.render(<GoogleReCaptchaProvider reCaptchaKey={Config.RECAPTCHA_KEY}><App /></GoogleReCaptchaProvider>, rootNode)
