import React, { useState, useEffect } from "react";
import './Header.scss';
import { useAuth } from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from 'react-router';
import Button from 'react-bootstrap/Button';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import i18n from '../i18n';
import * as Config from "../../config";
import Popup from "../Modals/Popup/Popup";

const Header = () => {
    const auth = useAuth();
    let history = useHistory();
    const [loginStatus, setLoginStatus] = useState(auth.isLoggedIn());
    const [popups, setPopups] = useState(null);
    let location = useLocation();
    const page = location.pathname.replace('/', '').replace('/', '-page ');
    const pageClass = (!page) ? 'home-page' : page + '-page';
    const loginClass = (loginStatus) ? ' logged-in' : '';

    document.body.className = pageClass + loginClass;
    const url = new URLSearchParams(useLocation().search);
    if(url.get('ref')) {
        if(!localStorage.getItem('ref')) {
            localStorage.setItem('ref', url.get('ref'));
        }
    }

    const [scrollPosition, setSrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setSrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        let isCancelledLoginStatus = false;
        auth.loginStatusChange.subscribe(x => {
            if (!isCancelledLoginStatus) {
                setLoginStatus(x);
            }
        });
        return () => {
            isCancelledLoginStatus = true;
        }
    }, [auth])

    const logOut = () => {
        auth.signOut();
    }


    useEffect(() => {
        let canceled = false;
        auth.showsPopups.subscribe(x=>{
            if(!canceled) {
                setPopups(x);
            }
        })
        return () => {
            canceled = true;
        }
    }, [auth])

    const getMode = () => {
        return localStorage.getItem('theme-mode') ? localStorage.getItem('theme-mode') : 'dark';
    }

    const changeThemeMode = () => {
        if (localStorage.getItem('theme-mode') === 'light') {
            localStorage.setItem('theme-mode', 'dark');
        } else {
            localStorage.setItem('theme-mode', 'light');
        }
        window.location.reload();
    }

    const getLogo = () => {
        if (getMode() === 'light') {
            return 'var(--logo-day)';
        } else {
            return 'var(--logo)';
        }
    }

    const changeLang = (lang) => {
        localStorage.setItem('i18nextLng', lang);
        window.location.href = `${getTheUrl}?lng=${lang}`;
    }

    const languages = { 'en': 'English', 'de': 'Deutsche' };
    const getTheUrl = window.location.origin + window.location.pathname;
    const languageflag = `/assets/img/flags/${localStorage.getItem('i18nextLng')}.png`;
    return <>
        <header className={`header stricky-fixed ${scrollPosition < 50 ? 'transparent' : ''}`}>
            {(loginStatus && auth.info) &&
                <div className="topHeader">
                    <div className="thm-container clearfix mx-auto px-0 text-right">
                        <span>Hello {auth.info.user.full_name}</span>
                        <span>PIN {auth.info.user.pin}</span>
                        <span className="verified" style={{color: auth.info.user.verified_account ? '#51a39a' : '#dc3545'}}>{auth.info.user.verified_account ? 'Verified' : 'Not Verified'}</span>
                        <span className="level">Level: {!Config.PLATFORM_ONLY ? <Link to="/account-levels">{auth.info.user.level}</Link>:auth.info.user.level}</span>
                        <span className="link" onClick={() => { logOut() }}>{i18n.t('menu.logout')}</span>
                    </div>
                </div>
            }
            <nav className="navbar navbar-default header-navigation stricky stricky-fixed">
                <div className="thm-container clearfix mx-auto px-0">
                    <Navbar variant="dark" expand="lg" collapseOnSelect>
                        <Nav.Link as={Link} to={!loginStatus ? '/' : '/platform'} href={!loginStatus ? '/' : '/platform'} className="navbar-brand px-0 d-flex"><div className={`logo ${Config.APP_NAME}`} style={{ backgroundImage: getLogo() }}></div></Nav.Link>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                        <Navbar.Collapse id="main-navbar-nav" className="justify-content-end">
                            <Nav>
                                {!loginStatus ? <>
                                    {!Config.PLATFORM_ONLY && <>
                                        <Nav.Link as={Link} to="/" href="/" className="nav-link"><Button variant="link">{i18n.t('menu.home')}</Button></Nav.Link>
                                        <Nav.Link as={Link} to="/about" href="/about" className="nav-link"><Button variant="link">{i18n.t('menu.about')}</Button></Nav.Link>
                                    </>}
                                </> : <>
                                        <Nav.Link as={Link} to="/platform/BTCUSD" href="/platform/BTCUSD" className="nav-link"><Button variant="link">{i18n.t('menu.platform')}</Button></Nav.Link>
                                        {!Config.PLATFORM_ONLY && <Nav.Link as={Link} to="/referrals" href="/referrals" className="nav-link"><Button variant="link">{i18n.t('menu.referrals')}</Button></Nav.Link>}
                                        <Nav.Link as={Link} to="/my-account" href="/my-account" className="nav-link"><Button variant="link">{i18n.t('menu.myAccount')}</Button></Nav.Link>
                                        <Nav.Link as={Link} to="/transactions/deposits" href="/transactions/deposits" className="nav-link"><Button variant="link">{i18n.t('menu.transactions')}</Button></Nav.Link>
                                        {Config.ECONOMICS &&<Nav.Link as={Link} to="/economic-calendar" href="/economic-calendar" className="nav-link"><Button variant="link">{i18n.t('menu.economics')}</Button></Nav.Link>}
                                    </>
                                }

                                {loginStatus ? <>
                                    <div className="wrapperModes">
                                        <input type="checkbox" className="mode" title="View Mode" defaultChecked={getMode() === 'light'} onChange={e => changeThemeMode()} />
                                        <NavDropdown title={<img src={languageflag} className="flag" alt={localStorage.getItem('i18nextLng')} />} id="basic-nav-dropdown2">
                                            {Object.keys(languages).map((item, i) => {
                                                return localStorage.getItem('i18nextLng') !== item && <Nav.Link key={i} onClick={() => changeLang(item)} className="dropdown-item">{languages[item]}</Nav.Link>
                                            })}
                                        </NavDropdown>
                                    </div>
                                </> :
                                    <>
                                        <Nav.Link as={Link} to="/register" href="/register" className="nav-link"><Button className="rounded-0">{i18n.t('menu.open_account')}</Button></Nav.Link>
                                        <Nav.Link as={Link} to="/login" href="/login" className="nav-link login-botton"><Button className="rounded-0" variant="light">{i18n.t('menu.login')}</Button></Nav.Link>
                                    </>
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </nav>
        </header>
        { popups && <Popup popups={popups} auth={auth} /> }
    </>;
}

export default Header;