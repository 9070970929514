import React, { useState } from "react";
import "./Markets.scss";
import { checkDataMarket } from "../../../hooks/helper";

export const Markets = ({ dataMarket, assetsGroups }) => {
  const markets = { forex: [], stocks: [], crypto: [], commodities: [], index: [] };
  const [showMarket, setShowMarket] = useState("forex");
  const [search, setSearch] = useState(null);

  return (
    <>
      <div className="card market">
        <div className="tabs-wrapper">
          <select className="tabs" onChange={(e) => setShowMarket(e.target.value)}>
            {Object.keys(markets).map((market) => {
              return (
                <option className={showMarket === market ? "active" : ""} value={market} key={market}>
                  {market}
                </option>
              );
            })}
          </select>
          <input type="text" placeholder={`Search in ${showMarket}`} onChange={(e) => setSearch(e.target.value)} />
        </div>

        <div className="table">
          <div className="row">
            <div className="col">Name</div>
            <div className="col">Value</div>
          </div>
          {assetsGroups[showMarket] &&
            Object.values(assetsGroups[showMarket]).map((item, i) => {
              if (dataMarket[item["symbol"]]) {
                const toShow = checkDataMarket(item["symbol"], dataMarket);
                return (
                  <div key={i}>
                    {toShow &&
                    (search === null ||
                      item["name"].toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                      item["symbol"].toLowerCase().indexOf(search.toLowerCase()) !== -1) ? (
                      <div className={toShow ? "row" : ""}>
                        <div className="col">
                          <a href={`/platform/${item["symbol"]}`}>{item["name"]}</a>
                        </div>
                        <div className="col">{Number.parseFloat(dataMarket[item["symbol"]].buy)}</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
            })}
        </div>
      </div>
    </>
  );
};
