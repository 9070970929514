import React, { useEffect, useState } from 'react';
import './OpenPosition.scss';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../hooks/useAuth';
import i18n from '../../../components/i18n';
import { usePositions } from '../../../hooks/usePositions';
import { Notification } from '../../../components/Notification/Notification';
import { Formating, StartListeningSocket } from '../../../hooks/helper';
import * as Config from "../../../config";

const OpenPosition = ({ access, leverage, currentAsset, dataMarket, free_margin }) => {
    const [customLeverage, setCustomLeverage] = useState(null);
    const [showNoti, setShowNoti] = useState(false);
    const auth = useAuth();
    const usePosition = usePositions();
    const { register, errors, reset, handleSubmit } = useForm();
    const symbol = currentAsset.symbol;

    if (auth.info && auth.info.user) StartListeningSocket(auth.info.user.token);

    useEffect(() => {
        let isCancelledCustomLeverage = false;
        if (!isCancelledCustomLeverage) {
            if (auth.info.user.custom_leverage) {
                const custom_leverage = JSON.parse(auth.info.user.custom_leverage);
                setCustomLeverage(custom_leverage[currentAsset.position_assets_category.name]);
            } else {
                auth.signOut();
            }
        }
        return () => {
            isCancelledCustomLeverage = true;
        };
    }, []);

    const openPosition = (data, direction) => {
        const lastSellPrice = Number.parseFloat(dataMarket[symbol].sell)
        const lastBuyPrice = Number.parseFloat(dataMarket[symbol].buy)
        if (data.amount <= free_margin && access && customLeverage !== null && leverage) {
            let price = (lastBuyPrice + (0.025 / 100) * lastBuyPrice);
            if (direction === 'sell') {
                price = (lastSellPrice - (0.025 / 100) * lastSellPrice);
            }
            usePosition.OpenPosition(auth.info, direction, data, price, symbol);
            setShowNoti(true);
            reset();
        }
    }

    return (
        <div className="openPosition">
            <div className="card">
                <form onSubmit={e => e.preventDefault()}>
                    <div className="wrapperContentSidebar">
                        <div className="title">{i18n.t('platform.new_trade')} <span>{currentAsset.name}</span></div>
                        <label>
                            {i18n.t('platform.amount')}
                            <span className={errors.amount ? 'error' : ''}>Max: {Formating(free_margin)}</span>
                        </label>
                        <div className="wrapper-amount" data-currency={Config.CURRENCY_SYMBOL}>
                            <input type="number" step="0.01" name="amount" ref={register({ required: true, max: free_margin, min: 1 })} placeholder="0.00" />
                        </div>

                        {(customLeverage !== null && leverage) && 
                            <div className="leverageOuterContainer">
                                <div className="leverageInnerContainer">
                                    {leverage.split(',').map((x, i) => {
                                        return <label key={i} className={`radioContainer ${!customLeverage.find(e => Number(e) === i) ? 'disable' : ''}`}>
                                            {customLeverage.find(e => Number(e) === i) && <input type="radio" name="leverage" value={x} ref={register({ required: true })} defaultChecked={(i === 0) ? true : false} />}
                                            <span className="checkmark">1:{x}</span>
                                        </label>
                                    })}
                                </div>
                            </div>
                        }
                        {access && customLeverage !== null && leverage ? <>
                            {!dataMarket[symbol] || !dataMarket[symbol].sell || dataMarket[symbol].sell === '0' || !dataMarket[symbol].buy || dataMarket[symbol].buy === '0' ?
                                <div className="you_have_no_access">{i18n.t('platform.temporarily_unavailable')}</div>
                            :
                                <div className="action">
                                        
                                        <>
                                            <button type="button" className="sell" onClick={handleSubmit((d) => { openPosition(d, 'sell') })}><span>{i18n.t('platform.sell_button')}</span> {dataMarket[symbol] ? Number.parseFloat(dataMarket[symbol].sell).toFixed(4) : 0}</button>
                                            <button type="button" className="buy" onClick={handleSubmit((d) => { openPosition(d, 'buy') })}><span>{i18n.t('platform.buy_button')}</span> {dataMarket[symbol] ? Number.parseFloat(dataMarket[symbol].buy).toFixed(4) : 0}</button>
                                        </>
                                    
                                </div>
                            }
                            </>
                            :
                            <div className="you_have_no_access">
                                {!auth.info.user.verified_account ? i18n.t('platform.account_not_verified') : i18n.t('platform.you_have_no_access')}
                            </div>

                        }

                        {errors.exampleRequired && <span>{i18n.t('platform.this_field_is_required')}</span>}
                    </div>
                </form>
            </div>
            <Notification show={showNoti} setShow={setShowNoti} title="Alert" text="New position was created successfully" />
        </div >
    );
}

export default OpenPosition