import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import './Summary.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faCheckCircle, faRobot } from '@fortawesome/free-solid-svg-icons';
import { Formating } from '../../../hooks/helper';
import i18n from '../../../components/i18n';
import { useAuth } from '../../../hooks/useAuth';
import { Deposit } from '../../../components/Modals/Deposit/Deposit';
import * as Config from "../../../config";

const Summary = ({summary, equity, free_margin, profit}) => {
    const auth = useAuth();
    const [showDeposit, setShowDeposit] = useState(false);
    const [robot, setRobot] = useState(auth.info.user.auto_trading);

    return (
        <>
            <div className="Summary">
                <div className="d-flex justify-content-between">
                    <div className="balance">
                        <strong>{i18n.t('platform.balance')}</strong>
                        <div>{summary.balance ? Formating(summary.balance, Config.CURRENCY_SYMBOL): 'Loading...'}</div>
                    </div>
                    <div className="equity">
                        <strong>{i18n.t('platform.equity')}</strong>
                        <div>{equity ? Formating(equity, Config.CURRENCY_SYMBOL): 'Loading...'}</div>
                    </div>
                    <div className="free_margin">
                        <strong>{i18n.t('platform.free_margin')}</strong>
                        <div>{free_margin ? Formating(free_margin, Config.CURRENCY_SYMBOL): 'Loading...'}</div>
                    </div>
                    <div className="profit">
                        <strong>{i18n.t('platform.profit')}</strong>
                        <div>{profit ? Formating(profit, Config.CURRENCY_SYMBOL): 'Loading...'}</div>
                    </div>
                    <div className="actions">
                        <Button className={`robot ${robot ? 'active' : ''}`} onClick={() => { auth.autoTrading(~~!robot); setRobot(robot => !robot) }}>
                            {robot ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faRobot} />}
                            {i18n.t('platform.robot')}</Button>
                        <Button className="deposit" onClick={() => setShowDeposit(true)}><FontAwesomeIcon icon={faArrowUp} /> {i18n.t('platform.deposit')}</Button>
                    </div>
                </div>
            </div>
            <Deposit modalIsVisible={showDeposit} setModalIsVisible={setShowDeposit} />
        </>
    );
}

export default Summary