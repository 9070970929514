import React, { useState } from "react";
import "./Contact.scss";
import { useForm } from "react-hook-form";
import { Input } from "../../components/Input";
import i18n from "../../components/i18n";
import { Button, Row, Col } from "react-bootstrap";
import axios from "axios";
import * as Config from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export const Contact = () => {
  const { register, handleSubmit, errors, reset } = useForm();
  const [loadingButton, setLoadingButton] = useState(false);
  const [thankYou, setThankYou] = useState(false);

  const onSubmit = (data) => {
    setLoadingButton(true);
    reset();
    setLoadingButton(false);
    axios.post(`${Config.INTEGROMAT_CONTACT}`, data).then((x) => {
      setThankYou(true);
    });
  };

  return (
    <div className="contact-page sec-pad">
      <h1>{i18n.t("contactUs.title")}</h1>
      {thankYou && (
        <div className="thankYou">
          {" "}
          <div className="thankYou">
            <FontAwesomeIcon icon={faCheckCircle} size="lg" />
            {i18n.t("contactUs.thankyou")}
          </div>
        </div>
      )}
      {!thankYou && (
        <>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Row>
              <Col>
                <Input
                  name="first_name"
                  type="text"
                  reference={register({ required: true })}
                  errors={errors}
                  title={i18n.t("contactUs.first_name.label")}
                  placeholder={i18n.t("contactUs.first_name.placeholder")}
                />
              </Col>
              <Col>
                <Input
                  name="last_name"
                  type="text"
                  reference={register({ required: true })}
                  errors={errors}
                  title={i18n.t("contactUs.last_name.label")}
                  placeholder={i18n.t("contactUs.last_name.placeholder")}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  name="email"
                  type="email"
                  reference={register({ required: true })}
                  errors={errors}
                  title={i18n.t("contactUs.email.label")}
                  placeholder={i18n.t("contactUs.email.placeholder")}
                />
              </Col>
              <Col>
                <Input
                  name="phone"
                  type="phone"
                  reference={register({ required: true })}
                  errors={errors}
                  title={i18n.t("contactUs.phone.label")}
                  placeholder={i18n.t("contactUs.phone.placeholder")}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label className="field">
                  {i18n.t("contactUs.message.placeholder")}
                  <textarea name="message" ref={register({ required: true })} placeholder={i18n.t("contactUs.message.placeholder")}></textarea>
                  {errors && errors["message"] && <span className="error">{i18n.t("contactUs.message.placeholder")} is required</span>}
                </label>
              </Col>
            </Row>
            <div>
              <Button className={`button ${loadingButton ? "loading" : ""}`} disabled={loadingButton} type="submit">
                {i18n.t("contactUs.button")}
              </Button>
            </div>
          </form>
          <div className="reCAPTCHA">
            This site is protected by reCAPTCHA and the Google{" "}
            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>{" "}
            apply.
          </div>
        </>
      )}
    </div>
  );
};
